




































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { ThingsFunctionEntityModel } from '@/entity-model/things-function-entity';
import ThingsFunctionEditComponent from './things-function-edit.vue';
import { ThingsFunctionType, ViewModeType, ThingsEntityType } from '@/model/enum';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { ThingsAttributeEntityModel } from '@/entity-model/things-attribute-entity';
import { ThingsMethodEntityModel } from '@/entity-model/things-method-entity';
import { ThingsEventEntityModel } from '@/entity-model/things-event-entity';

@Component({
    components: {
        'things-function-edit-dialog': ThingsFunctionEditComponent
    }
})
export default class ThingsFunctionListComponent extends BaseComponent {
    ThingsFunctionType = ThingsFunctionType;
    ThingsEntityType = ThingsEntityType;
    currentFunction: ThingsFunctionType = ThingsFunctionType.ATTRIBUTE;

    @Prop()
    thingsFunctionModel: ThingsFunctionEntityModel;
    @Prop()
    entityType: ThingsEntityType;

    get FunctionName(): string {
        return this.thingsFunctionModel.getFunctionName(this.currentFunction);
    }

    get TableColumns(): Array<any> {
        return _.filter(ThingsFunctionEntityModel.getFunctionTabelColumns(), (item: any) => (_.isUndefined(item.functionType) || item.functionType.indexOf(this.currentFunction) >= 0));
    }

    get FilterDataList(): Array<any> {
        return this.thingsFunctionModel.getFunctionList(this.currentFunction);
    }

    detailClick(model: any) {
        switch (this.currentFunction) {
            case ThingsFunctionType.ATTRIBUTE:
                (this.$refs.attributeFormDialog as FormDialogComponent<ThingsAttributeEntityModel>).dialogOpen(model, null, ViewModeType.VIEW);
                break;
            case ThingsFunctionType.METHOD:
                (this.$refs.methodFormDialog as FormDialogComponent<ThingsMethodEntityModel>).dialogOpen(model, null, ViewModeType.VIEW);
                break;
            case ThingsFunctionType.EVENT:
                (this.$refs.eventFormDialog as FormDialogComponent<ThingsEventEntityModel>).dialogOpen(model, null, ViewModeType.VIEW);
                break;
        }
    }

    addFunctionClick() {
        (this.$refs.functionEditFormDialog as any).dialogOpen(this.currentFunction, this.FilterDataList);
    }

    deleteFunctionClick(model: any) {
        this.startFullScreenLoading('正在删除...');
        this.thingsFunctionModel.deleteFunction(this.currentFunction, model)
            .then(res => {
                this.$emit('reload');
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }

    formDialogOK() {
        this.$emit('reload');
    }
}

